<template>
  <div class="sidebar BorderRight1px">
    <!-- <el-scrollbar
      style="height: 100%; box-sizing: border-box; padding-top: 4px"
    > -->
    <!-- $route.path 高亮关联url -->
      <el-menu
        :default-active="$route.path"
        :unique-opened="true"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        router
      >
        <template v-for="(item, index) in vipLeftTree">
          <el-menu-item
            :index="item.allPath"
            v-if="!item.children.length"
            :key="item.path"
            class="noChild"
            @click="goPath(item, index)"
          >
            <i :class="item.icon" class="iconfont"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
          <el-submenu
            :index="item.allPath"
            :key="item.allPath"
            v-else-if="item.children.length > 0"
          >
            <template slot="title">
              <i :class="item.icon" class="iconfont"></i>
              <span slot="title">{{ item.name }}</span>
            </template>
            <template>
              <el-menu-item
                :index="child.allPath"
                v-for="child in item.children"
                :key="child.path"
                @click="goPath(child, 2)"
              >
                {{ child.name }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </el-menu>
    <!-- </el-scrollbar> -->
    <!-- <i
      class="collapse"
      :title="isCollapse ? '展开' : '收起'"
      :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
      @click="isCollapse = !isCollapse"
    ></i> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validatenull } from "@/util/types";
export default {
  data() {
    return {
      isCollapse: false,
      leftmenu: [],
    };
  },
  computed: {
    // 会员中心左侧目录树
    ...mapGetters([
      "vipLeftTree",
    ]),
  },
  watch: {
  },
  created() {
  },
  methods: {
    validatenull(menu) {
      return validatenull(menu);
    },
    handleClose() {
    },
    handleOpen() {
    },
    // 点击切换路由
    goPath(item) {
      this.$router.push({ path: item.allPath }).catch(err => { console.log(err) });
    },
  }
}
</script>

<style lang="less" scoped>
.sidebar {
  z-index: 101;
}
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 240px;
// }
.collapse {
  position: absolute;
  bottom: 20px;
  right: 17px;
  font-size: 16px;
  color: red;
  cursor: pointer;
}
/deep/.el-menu--collapse {
  width: 50px;
}
/deep/.el-menu{
  border-right:none !important;
}
/deep/.el-submenu__title {
  padding: 0 4px !important;
}
/deep/.noChild {
  padding-left: 4px !important;
  .el-tooltip {
    padding: 0 4px !important;
  }
}
.iconfont {
  font-size: 16px;
  margin-right: 10px;
  margin-left: 10px;
}
</style>

