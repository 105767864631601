import { getPropertyType } from '@/api/exchange'
export const getLzlx = (type) => {
  let res = ''
  if (type == 0) {
    res = '出租'
  } else if (type == 1) {
    res = '入股'
  } else if (type == 2) {
    res = '转让'
  } else if (type == 3) {
    res = '出让'
  }
  return res
}

export const getCqlx = (type, list) => {
  // 初始化产权类型
  let res = ''
  list.map(x => {
    if (x.bh == type) {
      res = x.name
    }
  })
  return res
}

/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}